import useApi from '@api/transportLayer';
import { phone } from '@constants/common-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { SMSVerificationForm, TOTPVerificationForm, TwoFactorIgnoreForm } from '@screens/LoginPage/LoginComponents';
import VerifyCode from '@screens/LoginPage/TwoFactorPage/components/VerifyCode';
import { ETwoFactorType } from '@types';
import { set2faIgnorePage } from '@utils/auth-tokens';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useIdentity } from 'contexts/auth-context';
import TwoFactorMethodSelectionForm from '../LoginComponents/TwoFactorMethodSelectionForm';

enum STEPS {
  IGNORE_PROMPT = 'IGNORE_PROMPT',
  METHOD_SELECT = 'METHOD_SELECT',
  TOTP = 'TOTP',
  SMS = 'SMS',
  ADD_PHONE = 'ADD_PHONE',
}

interface IProps {
  onVerified: () => void;
  id: string;
  needs2fa: boolean;
}

const TwoFactorPage = ({ onVerified, id, needs2fa }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    state: { me },
  } = useIdentity();
  const [step, setStep] = useState<STEPS>(needs2fa ? STEPS.METHOD_SELECT : STEPS.IGNORE_PROMPT);
  const [qrCodeValue, setQrCodeValue] = useState<string>();
  const [error, setError] = useState<string>();
  const [sentCode, setSentCode] = useState<boolean>(false);
  const router = useRouter();

  const methodSelectSchema = yup
    .object({
      verificationType: yup.string(),
    })
    .required();

  const {
    handleSubmit: handleMethodSelectSubmit,
    control: methodSelectControl,
    formState: { isSubmitting: isSubmittingMethodSelect },
    setValue,
  } = useForm({
    defaultValues: {
      verificationType: '',
    },
    resolver: yupResolver(methodSelectSchema),
  });

  const smsVerificationSchema = yup
    .object({
      phoneNumber: yup.string().matches(phone, 'Invalid phone number.'),
    })
    .required();

  const ignorePromptSchema = yup
    .object({
      ignore2faPrompt: yup.string(),
    })
    .required();

  const {
    handleSubmit: handleSMSVerificationSubmit,
    control: smsVerificationControl,
    formState: {
      errors: errorsSMSVerification,
      isSubmitting: isSubmittingSMSVerification,
      dirtyFields: dirtyFieldsSMSVerification,
    },
    reset: resetSMSVerification,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
    },
    resolver: yupResolver(smsVerificationSchema),
  });

  const {
    handleSubmit: handleIgnoreSubmit,
    setValue: setIgnoreValue,
    control: ignoreControl,
    formState: { isSubmitting: isSubmittingIgnore },
  } = useForm({
    defaultValues: {
      ignore2faPrompt: 'setUpNow',
    },
    resolver: yupResolver(ignorePromptSchema),
  });

  const { mutate: ignore2faForever } = useApi.Auth.TwoFactorAuth.dismiss2faSetup();

  const { mutateAsync: enableTwoFactorAuthMethodDjango } = useApi.Auth.TwoFactorAuth.enable({
    onSuccess: (data) => {
      setQrCodeValue(data?.details);
      setError('');
      setLoading(false);
    },
    onError: (error) => {
      setError(error.response.message || error.data?.detail || 'Something went wrong.');
      setLoading(false);
    },
  });

  const handleTwoFactorMethodSelectionSubmit = (values) => {
    if (values.verificationType === 'authenticationApp') {
      onTotpSelected();
    } else if (values.verificationType === 'phoneCode') {
      onSMSSelected();
    }
  };

  const handleIgnore2faPromptSubmit = (values) => {
    if (values.ignore2faPrompt === 'setUpNow') {
      setStep(STEPS.METHOD_SELECT);
    } else if (values.ignore2faPrompt === 'remindLater') {
      onVerified();
      set2faIgnorePage('');
    } else {
      ignore2faForever({ userId: id, ignore_2fa_setup: true }); // Snake case because 2 is not a capital letter
      onVerified();
      set2faIgnorePage('');
    }
  };

  useEffect(() => {
    if (me?.company?.twoFactorEnabled) {
      setStep(STEPS.METHOD_SELECT);
    }
  }, [me?.company?.twoFactorEnabled]);

  const changeStep = (newStep) => {
    setStep(newStep);
    setSentCode(false);
    resetSMSVerification();
  };

  const handleSMSRedirect = (values) => {
    requestSMSCode(values?.phoneNumber);
  };

  async function onTotpSelected() {
    setLoading(true);
    setStep(STEPS.TOTP);
    enableTwoFactorAuthMethodDjango({ methodName: ETwoFactorType.TOTP_DJANGO });
  }

  const handleBackToLogin = () => {
    set2faIgnorePage('');
    router.reload();
  };

  async function onSMSSelected() {
    // we don't immediately send a code, let the user set up his phone
    setStep(STEPS.SMS);
  }

  async function requestSMSCode(newPhone?: string) {
    setLoading(true);
    setSentCode(true);
    enableTwoFactorAuthMethodDjango({ methodName: ETwoFactorType.SMS_DJANGO, phone: newPhone });
  }

  useEffect(() => {
    if (needs2fa) {
      setStep(STEPS.METHOD_SELECT);
      set2faIgnorePage('');
    } else {
      set2faIgnorePage('true');
    }
  }, [needs2fa]);

  if (step === STEPS.IGNORE_PROMPT) {
    return (
      <TwoFactorIgnoreForm
        redirectFn={handleIgnore2faPromptSubmit}
        backFn={handleBackToLogin}
        control={ignoreControl}
        handleSubmit={handleIgnoreSubmit}
        isSubmitting={isSubmittingIgnore}
        setValue={setIgnoreValue}
      />
    );
  }
  if (step === STEPS.METHOD_SELECT) {
    return (
      <TwoFactorMethodSelectionForm
        redirectFn={handleTwoFactorMethodSelectionSubmit}
        backFn={() => {
          if (me?.company?.twoFactorEnabled || needs2fa) {
            return router.reload();
          }
          changeStep(STEPS.IGNORE_PROMPT);
        }}
        control={methodSelectControl}
        handleSubmit={handleMethodSelectSubmit}
        isSubmitting={isSubmittingMethodSelect}
        setValue={setValue}
      />
    );
  }
  if (step === STEPS.SMS) {
    return (
      <SMSVerificationForm
        redirectFn={handleSMSRedirect}
        backFn={() => changeStep(STEPS.METHOD_SELECT)}
        handleSubmit={handleSMSVerificationSubmit}
        control={smsVerificationControl}
        errors={errorsSMSVerification}
        otherError={error}
        isSubmitting={isSubmittingSMSVerification}
        dirtyFields={dirtyFieldsSMSVerification}
      >
        <VerifyCode
          onVerified={onVerified}
          tokenType={ETwoFactorType.SMS}
          isActivationVerification={true}
          disabled={!sentCode}
        />
      </SMSVerificationForm>
    );
  }
  if (step === STEPS.TOTP && !loading) {
    return (
      <TOTPVerificationForm qrCodeValue={qrCodeValue} backFn={() => changeStep(STEPS.METHOD_SELECT)}>
        <VerifyCode tokenType={ETwoFactorType.TOTP} onVerified={onVerified} isActivationVerification={true} />
      </TOTPVerificationForm>
    );
  }

  return null;
};

export default TwoFactorPage;
