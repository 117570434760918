import useApi from '@api/transportLayer';
import { TwoFactorVerifyFooterLinks, TwoFactorVerifyForm } from '@screens/LoginPage/LoginComponents';
import VerifyCode from '@screens/LoginPage/TwoFactorPage/components/VerifyCode';
import { ETwoFactorType } from '@types';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

interface IProps {
  mfaType: ETwoFactorType;
  maskedPhone?: string;
  onVerified: () => any;
  ephemeralToken?: string | undefined;
}

const TwoFactorVerifyPage = ({ mfaType, maskedPhone, onVerified, ephemeralToken }: IProps) => {
  const { mutate: request2faSMS } = useApi.Auth.TwoFactorAuth.requestSMS();
  const [counter, setCounter] = useState<number>(0);

  const router = useRouter();

  function onVerifiedSuccess() {
    try {
      onVerified();
    } catch (error) {
      toast.error(error.message || error.data?.detail || 'Error verifying.');
    }
  }

  function requestSMSCode() {
    setCounter(59);
    request2faSMS({ method: ETwoFactorType.SMS_DJANGO });
  }

  useEffect(() => {
    if (!counter) return;

    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    // cleanup
    return () => clearInterval(intervalId);
  }, [counter]);

  return (
    <TwoFactorVerifyForm
      tokenType={mfaType}
      maskedPhone={maskedPhone}
      router={router}
    >
      <VerifyCode
        tokenType={mfaType}
        onVerified={onVerifiedSuccess}
        ephemeralToken={ephemeralToken}
      />
      <TwoFactorVerifyFooterLinks
        resendFn={mfaType === ETwoFactorType.SMS ? requestSMSCode : null}
        tokenType={mfaType}
        disabled={counter !== 0}
      />
    </TwoFactorVerifyForm>
  );
};

export default TwoFactorVerifyPage;
