import { routes } from '@constants/routes';
import { IUserVM } from '@types';
import { get2faIgnorePage } from '@utils/auth-tokens';
import { isInstallFlowSubdomain } from '@utils/rippling-install';
import { useIdentity } from 'contexts/auth-context';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { getHref } from 'router';
import { validUrl } from './validators';
import queryString from 'query-string';

const authorizedPaths = [
  routes.HR.DASHBOARD,
  routes.HR.NEW_LEAVE,
  routes.HR.EDIT_LEAVE,
  routes.HR.REVIEW_LEAVE,
  routes.HR.REDIRECT_LEAVE,
  routes.HR.VIEW_PLAN,
  routes.HR.PLAN_DETAILS_PAGE,
  routes.HR.PAYCALC_VERSION_HISTORY,
  routes.HR.PAYROLL_DASHBOARD,
  routes.EMPLOYEE.NEW_LEAVE,
  routes.EMPLOYEE.EDIT_LEAVE,
  routes.EMPLOYEE.VIEW_PLAN,
  '/settings',
  routes.ADMIN.TEMPLATES,
  routes.ADMIN.PLAN_DETAILS,
];

export const checkAuthorizedPage = (backToPath: string, me: IUserVM) => {
  // construct a new URL object from the redirect path and the current location origin
  // so that we can check simply the pathname of the redirect against our allowed paths
  let newUrl = new URL('/', location.origin);
  if (backToPath.startsWith('/')) {
    newUrl = new URL(backToPath, newUrl);
  }

  if (backToPath && validUrl(backToPath) && authorizedPaths.includes(newUrl.pathname)) {
    return backToPath;
  }
  if (me.isHr) {
    return routes.HR.DASHBOARD;
  }
  if (me.isPayroll) {
    return routes.HR.PAYROLL_DASHBOARD;
  }
  return '/';
};

const guestPage = (BaseComponent) => {
  const GuestPageComponent = (props) => {
    const router = useRouter();
    const {
      state: { me },
    } = useIdentity();
    const loggedIn = !!me;

    useEffect(() => {
      if (loggedIn && !get2faIgnorePage() && !isInstallFlowSubdomain()) {
        const sanitizedQuery = queryString.parse(queryString.stringify(router.query));
        const backTo = sanitizedQuery.backTo
          ? sanitizedQuery.backTo.toString().replace(/[^a-zA-Z0-9\-_.~:/?#\[\]@!$&'()*+,;=%]/g, '')
          : '/';
        const authorizedPageUrl = checkAuthorizedPage(backTo, me);
        router.push(getHref(authorizedPageUrl), authorizedPageUrl);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, me]); // exclude router to avoid infinite re-render

    if (loggedIn) {
      return null;
    }

    return React.createElement(BaseComponent, { router, ...props });
  };

  return GuestPageComponent;
};

export default guestPage;
