import { FormControl, FormLabel, RadioGroup, FormControlLabel, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Radio, Button } from '@ourtilt/tilt-ui-components';
import LoginLayout from '@layouts/LoginLayout';
import LoginHeader from './LoginHeader';
import HelpCenterLink from './HelpCenterLink';

const FormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '400px',
});

const TwoFactorMethodSelectionForm = ({
  redirectFn,
  backFn,
  handleSubmit,
  control,
  isSubmitting,
  setValue,
}: {
  redirectFn: (any) => void;
  backFn: () => void;
  control: any;
  handleSubmit: any;
  isSubmitting?: boolean;
  setValue?: any;
}) => {
  return (
    <LoginLayout>
      <FormContainer onSubmit={handleSubmit(redirectFn)}>
        <LoginHeader back={backFn} title="Set up your 2-Step Verification" />
        <Controller
          name="verificationType"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel sx={{ color: 'text.primary', '&.Mui-focused': { color: 'text.primary' } }}>
                Please select an authentication method
              </FormLabel>
              <RadioGroup
                {...field}
                onChange={(e) => {
                  setValue('verificationType', e.target.value, { shouldDirty: true });
                }}
              >
                <FormControlLabel value="authenticationApp" control={<Radio />} label="Authentication App" />
                <FormControlLabel value="phoneCode" control={<Radio />} label="Phone Code (SMS)" />
              </RadioGroup>
            </FormControl>
          )}
        />
        <Button variant="contained" color="primary" sx={{ mt: 3, mb: 3 }} disabled={isSubmitting} type="submit">
          {isSubmitting ? 'Loading...' : 'Next'}
        </Button>
      </FormContainer>
      <HelpCenterLink />
    </LoginLayout>
  );
};

export default TwoFactorMethodSelectionForm;
