import { BASE_AUTH_API_PATH, VERIFY_2FA_URL_PATH } from '@api/constants';
import useApi from '@api/transportLayer';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthCodeForm } from '@screens/LoginPage/LoginComponents';
import { ETwoFactorType } from '@types';
import { saveDeviceUID } from '@utils/auth-tokens';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IProps {
  onVerified: () => void;
  tokenType: ETwoFactorType;
  isActivationVerification?: boolean | undefined;
  ephemeralToken?: string | undefined;
  disabled?: boolean | false;
}

const VerifyCode = ({
  tokenType = ETwoFactorType.TOTP,
  isActivationVerification = false,
  ephemeralToken,
  onVerified,
  disabled,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const schema = yup
    .object({
      authCode: yup.string().required('6-digit code is required'),
      rememberDevice: yup.boolean(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, dirtyFields },
    watch,
  } = useForm({
    defaultValues: {
      authCode: '',
      rememberDevice: false,
    },
    resolver: yupResolver(schema),
  });

  const verifyTwoFactorAuthMethod = async (formData) => {
    try {
      const response = await fetch(`${VERIFY_2FA_URL_PATH}`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data?.deviceUID) {
        saveDeviceUID(data.deviceUID);
      }
      if (response.status !== 200) {
        throw new Error('Something went wrong, please try again later.');
      }
      onVerified();
      setLoading(false);
    } catch (error) {
      setError(error.data?.detail || 'Something went wrong, please try again later.');
      setLoading(false);
    }
  };

  const confirmEnableTwoFactorAuthMethod = async (formData) => {
    try {
      const methodName = tokenType === ETwoFactorType.TOTP ? ETwoFactorType.TOTP_DJANGO : ETwoFactorType.SMS_DJANGO;
      const response = await fetch(`${BASE_AUTH_API_PATH}/2fa/activate/confirm/${methodName}/`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      if (response.status !== 200) {
        throw new Error('Something went wrong, please try again later.');
      }
      onVerified();
      setLoading(false);
    } catch (error) {
      setError(error.data?.detail || 'Something went wrong, please try again later.');
      setLoading(false);
    }
  };

  function onActivateDjango(values) {
    setLoading(true);
    error && setError(null);
    if (isActivationVerification) {
      confirmEnableTwoFactorAuthMethod({
        code: values.authCode,
        remember2fa: values.rememberDevice,
        ephemeralToken,
        methodName: tokenType === ETwoFactorType.TOTP ? ETwoFactorType.TOTP_DJANGO : ETwoFactorType.SMS_DJANGO,
      });
    } else {
      verifyTwoFactorAuthMethod({
        code: values.authCode,
        remember2fa: values.rememberDevice,
        ephemeralToken,
      });
    }
  }

  return (
    <AuthCodeForm
      redirectFn={onActivateDjango}
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      otherError={error}
      isSubmitting={isSubmitting}
      dirtyFields={dirtyFields}
      watch={watch}
      buttonLabel="Log In"
      isLoading={loading}
      disabled={disabled}
    />
  );
};

export default VerifyCode;
