import useApi from '@api/transportLayer';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginForm, SubdomainForm } from '@screens/LoginPage/LoginComponents';
import { IUserVM, LoginResponse } from '@types';
import { checkAuthorizedPage } from '@utils/guest-page';
import {
  clearRipplingInstallFlowStorage,
  isInstallFlowSubdomain,
  isRipplingInstallFlow,
  retrieveRipplingInstallFlowValues,
} from '@utils/rippling-install';
import { useIdentity } from 'contexts/auth-context';
import { useRouter } from 'next/router';
import { isReservedSubdomain } from 'utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import TwoFactorPage from './TwoFactorPage';
import TwoFactorVerifyPage from './TwoFactorVerifyPage';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { BASE_AUTH_API_PATH, LEGACY_LOGIN_URL_PATH } from '@api/constants';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { getDeviceUID } from '@utils/auth-tokens';

export enum LOGIN_PHASE {
  NOT_AUTH,
  NEEDS_SECOND_FACTOR,
  NEEDS_CONFIGURE_2FA,
}
const Login = ({ isLoggedIn = false }: { isLoggedIn: boolean }) => {
  // NOTE: state.me *shouldn't* exist at this point, however if a user navigates to the login page
  // manually while they have a token we should try to use the token to fetch the user and set context
  const { state, dispatch: authDispatch } = useIdentity();

  // ensure that the auth state me is null on render
  useEffect(() => {
    if (!isLoggedIn) {
      authDispatch({ type: 'SET_ME', payload: null });
    }
  }, []);

  const [loginPhase, setLoginPhase] = useState(LOGIN_PHASE.NOT_AUTH);

  const router = useRouter();
  const [loginData, setLoginData] = useState<LoginResponse>();
  const [loginError, setLoginError] = useState('');
  const ssoCallbackError = router.query.error as string;
  const schema = yup
    .object({
      subdomain: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: {
      subdomain: '',
    },
    resolver: yupResolver(schema),
  });

  const subdomainValue = watch('subdomain');

  const { data: me, refetch: getMe, error: meError, isFetched } = useApi.Auth.getMe({}, { enabled: isLoggedIn });

  useEffect(() => {
    if (meError && isFetched && !!state.me) {
      datadogLogs.logger.error((meError as any)?.message || 'Unable to get me');
      authDispatch({ type: 'SET_ME', payload: null });
    }

    if (me && isFetched && !meError) {
      authDispatch({ type: 'SET_ME', payload: me });

      if (me) {
        const userObject: Record<string, any | IUserVM> = me;

        datadogRum.setUser({
          id: userObject.user?.id?.toString(),
          email: userObject.user?.email,
          superuser: userObject.user?.superuser,
          company: userObject?.company,
        });

        // route the user to the dashboard or the backTo parameter
        const sanitizedQuery = queryString.parse(queryString.stringify(router.query));
        const backTo = sanitizedQuery.backTo
          ? sanitizedQuery.backTo.toString().replace(/[^a-zA-Z0-9\-_.~:/?#\[\]@!$&'()*+,;=%]/g, '')
          : '/';
        const authorizedPageUrl = checkAuthorizedPage(backTo, userObject?.user);
        if (location.search.includes('backTo') && authorizedPageUrl) {
          router.push(backTo);
        } else {
          router.push('/');
        }
      }
    }
  }, [me, meError, isFetched]);

  const { refetch: getCompanyPublic, data: company } = useApi.Company.getOnePublic(
    {},
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (!company && !isReservedSubdomain()) {
      getCompanyPublic();
    }
  }, [company]);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const login = async (formData) => {
    try {
      const response = await fetch(LEGACY_LOGIN_URL_PATH, {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if (response.status === 401) {
        throw new Error('Invalid User or Password.', { cause: response.status });
      }

      const data = await response.json();
      setLoginData(data);
      setLoginPhase(data.loginPhase);
      if (data.loginPhase === LOGIN_PHASE.NOT_AUTH) {
        completeLogin();
      }
      return;
    } catch (error) {
      setLoginError(error?.message || 'Unable to login');
      setLoginPhase(LOGIN_PHASE.NOT_AUTH);
      return;
    }
  };

  const loginGoogle = async (values: Record<string, any>) => {
    try {
      const response = await fetch(`${BASE_AUTH_API_PATH}/google/login/`, {
        method: 'POST',
        body: JSON.stringify(values),
      });

      if (response.status === 401) {
        throw new Error('Invalid User or Password.', { cause: response.status });
      }

      const data = await response.json();
      setLoginData(data);
      setLoginPhase(data.loginPhase);
      completeLogin();
      return;
    } catch (error) {
      let message = 'Unable to login';
      const data = error.response?.data;
      if (data?.nonFieldErrors) {
        message = data.nonFieldErrors[0];
      } else if (data?.detail) {
        message = data.detail;
      }
      setLoginError(message || error?.message);
      setLoginPhase(LOGIN_PHASE.NOT_AUTH);
      return;
    }
  };

  // TODO: add API route
  const { mutateAsync: installWithRippling }: any = useApi.Auth.installWithRippling();

  const [isLoadingSso, setIsLoadingSso] = useState(false);
  const loginWithSSO = async () => {
    const inTwoHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
    Cookies.set('sso-login', '1', {
      expires: inTwoHours,
    });

    try {
      setIsLoadingSso(true);
      const response = await fetch(`${BASE_AUTH_API_PATH}/sso/login/`, {
        method: 'POST',
        body: JSON.stringify({}),
      });
      if (response.status !== 200) {
        throw new Error(response.statusText, { cause: response.status });
      }
      const data = await response.json();
      if (data.url) {
        router.push(data.url, undefined, { unstable_skipClientCache: true });
      }
      return data;
    } catch (e) {
      setLoginError(e?.cause ? `${e.message}: ${e.cause}` : e?.message || 'There was a server error');
    } finally {
      setIsLoadingSso(false);
    }
  };

  // TODO: add API route
  const { mutateAsync: checkSubdomain } = useApi.Auth.checkSubdomain({
    enabled: false,
    onSuccess: () => {
      setLoginError('');
      router.push(`${process.env.NEXT_PUBLIC_API_PROTOCOL}://${subdomainValue}.${process.env.NEXT_PUBLIC_SITE_DOMAIN}/`);
    },
    onError: (err) => {
      if (err?.response?.status === 404) {
        setLoginError('Subdomain not found.');
      }
    },
  });

  const ssoLogin = async () => {
    if (!company?.uuid) return;
    // set company-uuid cookie to expire in 3 minutes
    const inTwoMinutes = new Date(new Date().getTime() + 3 * 60 * 1000);
    Cookies.set('company-uuid', company?.uuid, { expires: inTwoMinutes, secure: true, sameSite: 'strict' });
    loginWithSSO();
  };

  useEffect(() => {
    const ssoLoginCookie = Cookies.get('sso-login');
    async function redirectToOktaLogin() {
      await ssoLogin();
    }
    if (company?.oktaSsoEnabled && !loginError && !ssoLoginCookie) {
      redirectToOktaLogin();
    }
  }, [company, loginError]);

  async function completeLogin() {
    // ! Note: right now users should only reach the secure subdomain
    // ! for login from Rippling during the install flow process
    if (isRipplingInstallFlow()) {
      // This will create rippling integration and redirect user back
      try {
        const { companyUuid } = await installWithRippling({
          ...retrieveRipplingInstallFlowValues(),
        });
        // redirect user back to Rippling and clear local storage params
        window.location.replace(clearRipplingInstallFlowStorage(companyUuid));
      } catch (e) {
        const error = e.response?.data?.detail || 'Unable to install the Rippling application.';
        setLoginError(error);
        if (loginPhase === LOGIN_PHASE.NEEDS_CONFIGURE_2FA || loginPhase === LOGIN_PHASE.NEEDS_SECOND_FACTOR) {
          setLoginPhase(LOGIN_PHASE.NOT_AUTH);
        }
      }
    } else {
      // NOTE: we can't do this in the Rippling install flow case since the guest page
      // will show a blank page and we will have to dispatch null me in the store to
      // get back to the login page on error, losing any install error messages
      await getMe();
    }
  }

  const handleSubdomainSubmit = (values) => {
    checkSubdomain({ ...values });
  };

  async function onLogin(values, isGoogleLogin = false) {
    setLoginError('');
    setIsLoggingIn(true);
    try {
      if (isGoogleLogin) {
        loginGoogle({
          ...values,
        });
      } else {
        // TODO: update with device_uuid to "remember this device"
        await login({
          ...values,
          device_uuid: getDeviceUID(),
        });
      }
    } catch (e) {
      setLoginError('Unable to login');
    } finally {
      setIsLoggingIn(false);
    }
  }
  switch (loginPhase) {
    case LOGIN_PHASE.NOT_AUTH: {
      const isLoginSubdomain = window.location.hostname.startsWith('login.');
      if (company?.oktaSsoEnabled) return null;
      if (isLoginSubdomain) {
        return (
          <SubdomainForm
            control={control}
            dirtyFields={dirtyFields}
            redirectFn={handleSubdomainSubmit}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            loginError={ssoCallbackError ? decodeURIComponent(ssoCallbackError) : loginError}
            setLoginError={setLoginError}
            errors={errors}
          />
        );
      } else {
        const incorrectPageError =
          isInstallFlowSubdomain() && !isRipplingInstallFlow()
            ? `You have reached an expired authorization page. Please log in using your
            company’s dedicated Tilt URL, or contact your HR administrator for support.`
            : '';

        return (
          <LoginForm
            company={company}
            isLoadingSsoResponse={isLoadingSso}
            isLoggingIn={isLoggingIn} // TODO LIFE-418 check isLoggingInGoogle as well?
            onLogin={onLogin}
            otherError={loginError || incorrectPageError}
            ssoLogin={ssoLogin}
            loginDisabled={!!incorrectPageError}
          />
        );
      }
    }
    case LOGIN_PHASE.NEEDS_CONFIGURE_2FA: {
      return (
        <TwoFactorPage
          needs2fa={loginData?.needs2fa}
          id={loginData?.id}
          onVerified={completeLogin}
        />
      );
    }
    case LOGIN_PHASE.NEEDS_SECOND_FACTOR: {
      return (
        <TwoFactorVerifyPage
          mfaType={loginData.type}
          maskedPhone={loginData.existingPhone}
          ephemeralToken={loginData.ephemeralToken}
          onVerified={completeLogin}
        />
      );
    }
  }
};

export default Login;
